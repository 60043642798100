<template>
	<div class="flex flex-wrap justify-center px-3 py-4 mx-auto">
		<EmptyList
			v-if="showEmptyState"
			image-src="/img/no-photos.png"
			copy="This business has no photos yet."
			class="mb-2"
		/>
		<PhotoCarouselModal
			:modal-id="BUSINESS_PHOTO_MODAL"
			title="Gallery"
			:active-slide="modalPhotoId"
			:slides="photos"
			:current-index="modalPhotoId"
		/>
		<div class="flex flex-wrap justify-center w-full md:justify-start">
			<BusinessPhotoCard
				v-for="(photo, index) in photos"
				:key="index"
				:photo="photo"
				class="w-full md:w-1/3 lg:w-1/4"
				@click.native="openModal(BUSINESS_PHOTO_MODAL, index + 1)"
			/>
		</div>
		<div
			v-if="loading"
			class="grid w-full grid-cols-1 gap-16 py-16 text-xl font-bold text-center"
		>
			<div class="w-full text-gray-300">
				Loading...
			</div>
			<LoadingSpinner class="w-1/4 mx-auto text-gray-300 xl:w-1/6" />
		</div>
	</div>
</template>

<script async>
import { mapMutations } from 'vuex'

import BusinessPhotoCard from '@/components/business/BusinessPhotoCard.vue'
import EmptyList from '@/components/multiUse/EmptyList.vue'
import LoadingSpinner from '@/components/multiUse/LoadingSpinner.vue'
import PhotoCarouselModal from '@/components/UI/modals/PhotoCarouselModal.vue'
import { BUSINESS_PHOTO_MODAL } from '@/constants/modal/names.js'
import { GetBusinessPhotosPageData } from '@/gql/business/queries/pages.gql'
import { componentLevelGQLErrors } from '@/utils/error-handling.js'

export default {
	components: {
		BusinessPhotoCard,
		EmptyList,
		PhotoCarouselModal,
		LoadingSpinner
	},
	data: function () {
		return {
			currentPage: 0,
			perPage: 16,
			stopScroll: false,
			hover: false,
			modalPhotoId: 0,
			BUSINESS_PHOTO_MODAL,
			observer: {},
			lastElement: '',
			isMounted: false,
			loading: 0
		}
	},
	apollo: {
		photosPageData: {
			query: GetBusinessPhotosPageData,
			update(data) {
				if (data.meta) { this.$store.commit('setMeta', data.meta) }
				if (data.meta?.location) { this.$store.commit('setLocation', data.meta) }
				if (data?.listing) {
					return {
						id: data.listing.id,
						images: data.listing.images
					}
				}
				return []
			},
			variables () {
				return {
					seoUrl: this.$route.params.business || '',
					chainSeoUrl: this.$route.params.chain || '',
					photosLimit: this.perPage,
					photosOffset: 0 // #NOTE: this is set to `0` to append the results for non-paginated list (infinite scroll)
				}
			},
			error(error) {
				componentLevelGQLErrors(error)
			}
		}
	},
	computed: {
		showEmptyState() {
			return !this.hasPhotos && !this.loading
		},
		photos() {
			return this.photosPageData?.images || []
		},
		hasPhotos() {
			return !!this.photos.length
		},
		hasLastElement() {
			return this.isElement(this.lastElement)
		},
		browserSupportsIO() {
			return window && 'IntersectionObserver' in window
		}
	},
	watch: {
		lastElement(_, oldVal) {
			if (this.hasLastElement && !oldVal) {
				this.startObserver()
			} else {
				this.changeObserver(this.lastElement)
			}
		}
	},
	mounted() {
		this.isMounted = true
		this.setScrollTargets()
	},
	updated() {
		this.setScrollTargets()
	},
	methods: {
		...mapMutations('modal', [ 'showModal' ]),
		openModal(modalId, photoIndex) {
			this.modalPhotoId = photoIndex
			this.showModal(modalId)
		},
		setScrollTargets() {
			if (this.hasPhotos) {
				const scrollTargets = document.querySelectorAll('div.observe')
				this.lastElement = scrollTargets[scrollTargets.length - 1]
			}
		},
		observerCallback(entries, observer) {
			if (entries[0].isIntersecting && this.browserSupportsIO) {
				observer.unobserve(entries[0].target)
				this.getNextPage()
			}
		},
		startObserver() {
			if (this.hasLastElement && this.browserSupportsIO) {
				this.observer = new IntersectionObserver(this.observerCallback)
				this.observer.observe(this.lastElement)
			}
		},
		changeObserver(newElement) {
			if (this.hasLastElement && this.browserSupportsIO) {
				this.observer.observe(newElement)
			}
		},
		isElement(obj) {
			return obj instanceof HTMLElement
		},
		async getNextPage() {
			this.currentPage++

			try { // #NOTE: This is how we set up a non-paginated list (infinite scroll)
				// We also set the offset to `0` in the original query's `variables` object
				await this.$apollo.queries.photosPageData.fetchMore({
					variables: {
						photosOffset: this.currentPage * this.perPage,
						photosLimit: this.perPage
					},
					updateQuery(previousResult, { fetchMoreResult }) {
						if (!fetchMoreResult?.listing?.images) {
							return previousResult
						}
						return {
							listing: {
								id: previousResult.listing.id,
								images: [
									...previousResult.listing.images,
									...fetchMoreResult.listing.images
								],
								__typename: previousResult.listing.__typename
							},
							meta: {
								...previousResult.meta,
								__typename: previousResult.meta.__typename
							},
							__typename: previousResult.__typename
						}
					}
				})
			} catch (error) {
				componentLevelGQLErrors(error)
			}
		}
	}
}
</script>
