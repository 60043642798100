<template>
	<div class="cursor-pointer max-w-320 observe">
		<div class="p-3 mx-2 mb-2 border border-gray-300 shadow-lg rounded-xl xl:hover:shadow-xl">
			<WwImage
				:src="photo.fileName"
				classes="rounded-xl"
				:alt="caption"
				:fallback-image="DEFAULT_WW_GRAY_LOGO"
				:width="250"
				:height="250"
				:image-path="LISTING_IMAGES"
			/>
		</div>
	</div>
</template>

<script async>
import { LISTING_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'

export default {
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue')
	},
	props: {
		photo: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			DEFAULT_WW_GRAY_LOGO,
			LISTING_IMAGES
		}
	},
	computed: {
		caption () {
			return this.photo.caption || 'Where\'s Weed'
		}
	}
}
</script>

<style lang="scss" scoped>
.max-w-320 {
	max-width: 320px;
}
</style>
