<template>
	<div class="w-full text-white">
		<WwModal
			:modal-id="modalId"
			hide-footer
			shadow="shadow-none"
			background-color="bg-transparent"
			y-overflow-hidden
		>
			<template #close>
				<div class="absolute top-0 right-0">
					<button
						class="w-6 h-6 m-2 text-xs font-bold leading-4 text-black bg-white border rounded-full"
						@click.prevent="handleClose"
					>
						✕
					</button>
				</div>
			</template>

			<template #header>
				<div class="absolute w-1/2 text-lg font-bold top-2 left-5 xl:left-20 2xl:left-5">
					{{ title }}
				</div>
			</template>
			<template #body>
				<div class="flex items-center justify-center object-contain w-full h-full m-auto">
					<WwCarousel
						:item-count="slides.length"
						:items-per-slide="1"
						side-scroller-class="pb-8"
						scrollbar-visibility="hide-horizontal-scrollbar"
						fade-visibility="hidden"
						right-arrow-position="-right-10"
						:initial-slide="initialSlide"
						no-bullet-ring-offset
						autoplay
						scroll-behavior="auto"
						class="w-full"
					>
						<template #slides>
							<div
								v-for="(slide, index) in slides"
								:key="slide.fileId"
								class="flex flex-shrink-0 w-full"
							>
								<div
									:ref="CAROUSEL_ITEM"
									class="relative mx-auto"
								>
									<WwImage
										:src="slide.fileName"
										classes="rounded-xl"
										object-cover
										:alt="`slide-${index}`"
										:fallback-image="DEFAULT_WW_GRAY_LOGO"
										:width="imageWidth"
										:height="imageHeight"
										:image-path="slide.type || imagePath"
									/>
								</div>
							</div>
						</template>
					</WwCarousel>
				</div>
			</template>
		</WwModal>
	</div>
</template>

<script async>
import { mapMutations } from 'vuex'

import WwCarousel from '@/components/UI/WwCarousel.vue'
import WwModal from '@/components/UI/WwModal.vue'
import CAROUSEL_ITEM from '@/constants/carousel/carousel-item.js'
import { LISTING_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'

export default {
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue'),
		WwModal,
		WwCarousel
	},
	props: {
		modalId: {
			type: String,
			required: true
		},
		title: {
			type: String,
			default: ''
		},
		imageWidth: {
			type: Number,
			default: 500
		},
		imageHeight: {
			type: Number,
			default: 500
		},
		currentIndex: {
			type: Number,
			required: true
		},
		slides: {
			type: Array,
			required: true
		},
		imagePath: {
			type: String,
			default: LISTING_IMAGES
		}
	},
	data: function() {
		return {
			DEFAULT_WW_GRAY_LOGO,
			CAROUSEL_ITEM,
			isMounted: false,
			initialSlide: 0
		}
	},
	watch: {
		currentIndex: {
			immediate: true,
			handler(newVal) {
				if (newVal && this.isMounted) this.setCurrentSlide(newVal)
			}
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		...mapMutations('modal', [ 'closeModal' ]),
		handleClose(event) {
			event.stopPropagation()
			this.closeModal()
		},
		setCurrentSlide(newIndex) {
			this.initialSlide = newIndex
		}
	}
}
</script>
